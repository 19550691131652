@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body{
    font-family: 'Nunito';
    color: #b3b3b3;
}

.bg-img{
    background-image: url('./Assets/newbackground.jpeg')
}